import React from 'react'
import { type RPPDomain } from '@retailer-platform/dashboard/core'
import { NavItem, NavEntryPoint } from '@retailer-platform/dashboard/routing'
import { NewBadge } from '@retailer-platform/dashboard/gin-and-tonic'
import { v1Router, v2Router, v3Router } from './routing/router'
import { type DomainMessages } from './utils/domain/intl'
import { routes } from './routing/routes'
import EN_US from './translations/en-US'
import { type DomainAccessControlConfig } from './utils/domain/accessControl'
import { retailerCollectionsV2RetailerAccessControl } from './access-control/retailerCollectionsV2Access.configuration'
import { retailerCollectionsV3AccessControl } from './access-control/retailerCollectionsV3Access.configuration'
import { retailerCollectionsFlyersAccessControl } from './access-control/retailerCollectionsFlyersAccess.configuration'

const definition: RPPDomain<typeof routes, DomainMessages, DomainAccessControlConfig> = {
  id: 'retailer-collections',
  totem: {
    entity: 'retailer-collections-dashboard-tool',
  },
  routes,
  translations: { EN_US },
  routers: {
    scoped: [v1Router, v2Router],
    partnerScoped: [v3Router],
  },
  navigationEntries: [
    {
      attachTo: NavItem.Catalog,
      attachToV2: NavEntryPoint.Merchandising,
      subNavItems: [
        {
          type: 'item',
          labelId: 'retailerCollectionsDomain.v2.list.title',
          route: 'retailer-collections',
          accessControl: retailerCollectionsV2RetailerAccessControl,
          positionNavV2: 0,
        },
        {
          type: 'item',
          labelId: 'retailerCollectionsDomain.v3.list.title',
          route: 'retailer-collections-v3',
          accessControl: retailerCollectionsV3AccessControl,
          positionNavV2: 0,
        },
      ],
    },
    {
      attachTo: NavItem.RetailerFundedMarketing,
      attachToV2: NavEntryPoint.Marketing,
      subNavItems: [
        {
          type: 'item',
          labelId: 'retailerCollectionsDomain.flyers.list.title',
          route: 'retailer-collections-flyers',
          accessControl: retailerCollectionsFlyersAccessControl,
          navBadge: React.createElement(NewBadge, null),
          isNew: true,
          positionNavV2: 4,
        },
      ],
    },
  ],
  adminNavigationEntries: [],
}

export default definition
