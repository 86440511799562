import {
  type DashRouteType,
  URL_RETAILER_SCOPED,
  URL_PARTNER_SCOPED,
} from '@retailer-platform/dashboard/routing'

export const URL_RETAILER_COLLECTIONS = `${URL_RETAILER_SCOPED}/retailer-collections`
export const URL_RETAILER_COLLECTIONS_V2 = `${URL_RETAILER_SCOPED}/retailer-collections/v2`
export const URL_RETAILER_COLLECTIONS_V3 = `${URL_PARTNER_SCOPED}/retailer-collections`

const URL_RETAILER_COLLECTIONS_LIST = `${URL_RETAILER_COLLECTIONS}/list`
const URL_RETAILER_COLLECTIONS_CREATE = `${URL_RETAILER_COLLECTIONS}/create`
const URL_RETAILER_COLLECTIONS_VIEW = `${URL_RETAILER_COLLECTIONS}/view/:id`
const URL_RETAILER_COLLECTIONS_EDIT = `${URL_RETAILER_COLLECTIONS}/edit/:id`
const URL_RETAILER_COLLECTIONS_BULK_UPLOAD = `${URL_RETAILER_COLLECTIONS}/bulk`

const URL_RETAILER_COLLECTIONS_V2_LIST = `${URL_RETAILER_COLLECTIONS_V2}/list`
const URL_RETAILER_COLLECTIONS_V2_CREATE = `${URL_RETAILER_COLLECTIONS_V2}/create`
const URL_RETAILER_COLLECTIONS_V2_VIEW = `${URL_RETAILER_COLLECTIONS_V2}/view/:id`
const URL_RETAILER_COLLECTIONS_V2_EDIT = `${URL_RETAILER_COLLECTIONS_V2}/edit/:id`
const URL_RETAILER_COLLECTIONS_V2_BULK_UPLOAD = `${URL_RETAILER_COLLECTIONS_V2}/bulk`
const URL_RETAILER_COLLECTIONS_FLYERS = `${URL_RETAILER_COLLECTIONS}/flyers`
const URL_RETAILER_COLLECTIONS_FLYERS_BUILD = `${URL_RETAILER_COLLECTIONS}/flyers/build/:id`
const URL_RETAILER_COLLECTIONS_FLYERS_REVIEW = `${URL_RETAILER_COLLECTIONS}/flyers/review/:key`

const URL_RETAILER_COLLECTIONS_V3_LIST = `${URL_RETAILER_COLLECTIONS_V3}/list`
// @hygen:inject scoped-urls

export type RetailerCollectionsV2ViewParams = {
  id: string
}

export type RetailerCollectionsV2EditParams = {
  id: string
}

export type DashRouteSet =
  | DashRouteType<'retailer-collections'>
  | DashRouteType<'retailer-collections-list'>
  | DashRouteType<'retailer-collections-browse'>
  | DashRouteType<'retailer-collections-view', RetailerCollectionsV2ViewParams>
  | DashRouteType<'retailer-collections-create'>
  | DashRouteType<'retailer-collections-edit', RetailerCollectionsV2EditParams>
  | DashRouteType<'retailer-collections-bulk-upload'>
  | DashRouteType<'retailer-collections-v2'>
  | DashRouteType<'retailer-collections-v2-list'>
  | DashRouteType<'retailer-collections-v2-create'>
  | DashRouteType<'retailer-collections-v2-view', RetailerCollectionsV2ViewParams>
  | DashRouteType<'retailer-collections-v2-edit', RetailerCollectionsV2EditParams>
  | DashRouteType<'retailer-collections-v2-bulk-upload'>
  | DashRouteType<'retailer-collections-flyers'>
  | DashRouteType<'retailer-collections-flyers-build'>
  | DashRouteType<'retailer-collections-flyers-review'>
  | DashRouteType<'retailer-collections-v3'>
  | DashRouteType<'retailer-collections-v3-list'>
// @hygen:inject dash-route-set

export const routes: { [Key in DashRouteSet['identifier']]: string } = {
  'retailer-collections': URL_RETAILER_COLLECTIONS,
  'retailer-collections-browse': URL_RETAILER_COLLECTIONS_LIST,
  'retailer-collections-list': URL_RETAILER_COLLECTIONS_LIST,
  'retailer-collections-view': URL_RETAILER_COLLECTIONS_VIEW,
  'retailer-collections-create': URL_RETAILER_COLLECTIONS_CREATE,
  'retailer-collections-edit': URL_RETAILER_COLLECTIONS_EDIT,
  'retailer-collections-bulk-upload': URL_RETAILER_COLLECTIONS_BULK_UPLOAD,

  'retailer-collections-v2': URL_RETAILER_COLLECTIONS_V2,
  'retailer-collections-v2-list': URL_RETAILER_COLLECTIONS_V2_LIST,
  'retailer-collections-v2-create': URL_RETAILER_COLLECTIONS_V2_CREATE,
  'retailer-collections-v2-view': URL_RETAILER_COLLECTIONS_V2_VIEW,
  'retailer-collections-v2-edit': URL_RETAILER_COLLECTIONS_V2_EDIT,
  'retailer-collections-v2-bulk-upload': URL_RETAILER_COLLECTIONS_V2_BULK_UPLOAD,
  'retailer-collections-flyers': URL_RETAILER_COLLECTIONS_FLYERS,
  'retailer-collections-flyers-build': URL_RETAILER_COLLECTIONS_FLYERS_BUILD,
  'retailer-collections-flyers-review': URL_RETAILER_COLLECTIONS_FLYERS_REVIEW,

  'retailer-collections-v3': URL_RETAILER_COLLECTIONS_V3,
  'retailer-collections-v3-list': URL_RETAILER_COLLECTIONS_V3_LIST,
  // @hygen:inject route-pair
}

export type DomainRouteName = keyof typeof routes
