import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import styled from '@emotion/styled'
import {
  Center,
  CenteredContent,
  colors,
  FullWidthContent,
  LoadingDots,
  spacing,
  useBooleanState,
} from '@retailer-platform/shared-components'
import { ChevronDownIcon } from '@instacart/ids-core'
import { Text } from '@instacart/ids-customers'
import { SelectedStoreConfigurationProvider } from '../selected-site-provider/SelectedSiteProvider'
import { SiteSelectModal } from '../site-select-modal/SiteSelectModal'
import {
  getDisplaySiteUrl,
  type SiteOption,
  SiteSelectOption,
  type Site,
  type SiteOperationIntent,
} from '../site-select/SiteSelect'
import { type DashRouteComponentProps } from '../../../legacy/dash-components/dash-route/utils/dashRoute.types'
import {
  type InstacartStoreConfiguration,
  StoreConfigurationRelationshipEnum,
} from '../../../__codegen__/api'
import { useWarehouseContext } from '../../../utils/contexts/warehouse/WarehouseContext.hooks'
import { useDashMessages } from '../../../intl/intl.hooks'
import { useRetailersStoreConfigurations } from '../../../api/store-configurations/useStoreConfigurations.hooks'
import { baseUseGoToPath } from '../../../utils/core/factories/routing/routing'

export type FilterSitesFn = (siteOptions: SiteOption[]) => SiteOption[]

export interface SelectedSitePageProps<DomainRouteNames>
  extends Pick<
    DashRouteComponentProps<{
      storeConfigId: string
      momentId?: string
    }>,
    'match'
  > {
  children?: React.ReactNode
  operationIntent: SiteOperationIntent
  routeNameToCurrentPage: DomainRouteNames
  filterSites?: FilterSitesFn
}

export const SelectedSitePage = <DomainRouteNames extends string>(
  props: SelectedSitePageProps<DomainRouteNames>
) => {
  const pathStoreConfigId = props.match.params.storeConfigId
  const undefinedStoreConfigId = !pathStoreConfigId || pathStoreConfigId === 'undefined'

  const warehouseContext = useWarehouseContext()
  const i18n = useDashMessages(
    {
      loading: 'sites.loading',
      error: 'sites.error',
      unexpectedErrorDescription: 'sites.unexpectedErrorDescription',
      noStoreFronts: 'sites.noSites',
      noSitesAvailableForX: 'sites.noSitesAvailableForX',
      siteNotFound: 'sites.siteNotFound',
      selectASiteAbove: 'sites.selectASiteAbove',
    },
    { warehouseName: warehouseContext?.warehouse?.name }
  )

  const { data, loading, error } = useRetailersStoreConfigurations({
    storeConfigRelationship: StoreConfigurationRelationshipEnum.Associated,
  })
  const selectedSc = data?.storeConfigurationsByRetailerIds?.find(sc => sc.id === pathStoreConfigId)
  let siteComponent = <SelectedSite />
  let body: React.ReactNode

  const loadSuccess = !loading && !error
  const [isModalShowing, showSelectModal, hideSelectModal] = useBooleanState()
  const onRequestClose = useCallback(() => {
    if (undefinedStoreConfigId) {
      //do not allow the user to continue without selecting a store front
      return
    }

    hideSelectModal()
  }, [hideSelectModal, undefinedStoreConfigId])

  const modalSites = useMemo(() => {
    const sites = data?.storeConfigurationsByRetailerIds?.sort(sc => parseInt(sc.id)).reverse()

    let siteOptions = sites?.map((site): SiteOption => {
      const disabled = props.operationIntent === 'modify' && !site.userAccessToModify

      return {
        site: site,
        selectOption: disabled ? SiteSelectOption.SELECT_NOT_APPLICABLE : SiteSelectOption.ALLOW,
      }
    })

    if (siteOptions && props.filterSites) {
      siteOptions = props.filterSites(siteOptions)
    }

    return siteOptions
  }, [data?.storeConfigurationsByRetailerIds, props])

  const buildGoToSite = useCallback(
    (site: Site) =>
      baseUseGoToPath(props.routeNameToCurrentPage, {
        storeConfigId: site.id,
        momentId: props.match.params?.momentId,
      }),
    [props.routeNameToCurrentPage, props.match.params]
  )

  useEffect(() => {
    if (undefinedStoreConfigId) {
      // handles if the storeConfigId is not selected
      showSelectModal()
    }
  }, [undefinedStoreConfigId, showSelectModal])

  if (loading) {
    siteComponent = <Text typography="bodyMedium1">{i18n.loading}</Text>
    body = (
      <FullWidthContent css={{ height: '100%' }}>
        <Center>
          <LoadingDots />
        </Center>
      </FullWidthContent>
    )
  } else if (error) {
    body = (
      <NegativeResponse
        title={i18n.error}
        subtitle={i18n.unexpectedErrorDescription}
        message={error.message}
        data-testid="errorMessage"
      />
    )
  } else if (
    !data?.storeConfigurationsByRetailerIds ||
    data?.storeConfigurationsByRetailerIds.length === 0
  ) {
    // no SC data? weird...
    body = (
      <NegativeResponse
        title={i18n.noStoreFronts}
        subtitle={i18n.noSitesAvailableForX}
        data-testid="noSites"
      />
    )
  } else if (undefinedStoreConfigId) {
    // this is normal failure case that will show the modal using useEffect
    // this is a normal failure so mark as success
  } else if (!selectedSc) {
    // could not find the SC matching the ID in the URL
    body = (
      <NegativeResponse
        title={i18n.siteNotFound}
        subtitle={i18n.selectASiteAbove}
        data-testid="storeSiteFound"
      />
    )
  } else {
    // successfully loaded everything
    siteComponent = <SelectedSiteItem storeConfig={selectedSc} />
    body = (
      <SelectedStoreConfigurationProvider
        selectedStoreConfiguration={selectedSc}
        selectedStoreConfigurations={[selectedSc]}
      >
        {props.children}
      </SelectedStoreConfigurationProvider>
    )
  }

  const [headerHeight, setHeaderHeight] = useState(0)
  const pageHeaderRef = useRef(null)

  useEffect(() => {
    setHeaderHeight(pageHeaderRef.current?.clientHeight ?? 0)
  }, [pageHeaderRef])

  return (
    <div css={{ height: '100vh' }}>
      <PageHeaderContainer ref={pageHeaderRef}>
        <SiteItemContainer onClick={showSelectModal} data-testid="siteItemContainer">
          {siteComponent}
        </SiteItemContainer>
      </PageHeaderContainer>
      <div
        css={{
          height: `calc(100% - ${headerHeight}px)`,
          width: '100%',
        }}
      >
        {body}
      </div>

      <SiteSelectModal
        isOpen={loadSuccess && isModalShowing}
        onRequestClose={onRequestClose}
        buildGoToSite={buildGoToSite}
        onSelect={hideSelectModal}
        hideCloseButton={undefinedStoreConfigId}
        sites={modalSites}
      />
    </div>
  )
}

const CARROT_LOGO =
  'https://www.instacart.com/assets/beetstrap/brand/2022/carrotlogo-1286c257354036d178c09e815906198eb7f012b8cdc4f6f8ec86d3e64d799a5b.png'

const SelectedSite = () => {
  const i18n = useDashMessages({ selectASite: 'sites.selectASite' })

  return (
    <>
      <StyledLogoImage src={CARROT_LOGO} />
      <Text typography="bodyMedium1">{i18n.selectASite}</Text>
      <ChevronDownIcon color="systemGrayscale50" size={20} />
    </>
  )
}

const SelectedSiteItem = (props: { storeConfig: InstacartStoreConfiguration }) => (
  <>
    <StyledLogoImage src={props.storeConfig.logoUrl || CARROT_LOGO} />
    <Text typography="bodyMedium1">{props.storeConfig.brandedName}</Text>
    <Text typography="bodySmall2">{'-'}</Text>
    <Text typography="bodyMedium2">{getDisplaySiteUrl(props.storeConfig) || ''}</Text>
    <ChevronDownIcon color="systemGrayscale50" size={20} />
  </>
)

const NegativeResponse = (props: { title: string; subtitle: string; message?: string }) => (
  <CenteredContent css={{ height: '100%' }} {...props}>
    <p>
      <Center>
        <Text typography="bodyLarge1">{props.title}</Text>
      </Center>
    </p>
    <p>
      <Center>
        <Text typography="bodyMedium1">{props.subtitle}</Text>
      </Center>
    </p>
    <p>
      <Center>
        <Text typography="bodySmall2">{props.message}</Text>
      </Center>
    </p>
  </CenteredContent>
)

const PageHeaderContainer = styled.div({
  padding: spacing.X12,
  paddingLeft: spacing.X24,
})

const SiteItemContainer = styled.div({
  width: 'fit-content',
  display: 'flex',
  flexDirection: 'row',
  padding: spacing.X8,
  gap: spacing.X8,
  alignItems: 'center',
  border: `2px solid ${colors.GRAYSCALE.X20}`,
  borderRadius: spacing.X12,
  ':hover': {
    backgroundColor: colors.GRAYSCALE.X20,
    cursor: 'pointer',
  },
})

const StyledLogoImage = styled.img({
  width: 20,
  height: 20,
  background: 'white',
  borderRadius: '50%',
  objectFit: 'cover',
})
