import { components, type MenuProps } from 'react-select-5'
import React, { Fragment, type FunctionComponent } from 'react'
import styled from '@emotion/styled'
import { Button, spacing } from '@retailer-platform/shared-components'
import { type SimpleOption } from '@retailer-platform/shared-components/src/common/select/utils/select.types'
import { useDashMessages } from '../../../intl/intl.hooks'

const MenuActionsApplyContainer = styled.div({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  width: '100%',
  padding: `${spacing.X12} ${spacing.X20}`,
  boxShadow: 'none',
})
const MenuActionButton = styled(Button)({
  height: '40px',
  width: '48%',
  margin: 0,
})

interface Props {
  props: MenuProps<SimpleOption, any, any>
  onApply: () => void
  onCancel: () => void
}

export const MultiSelectMenu: FunctionComponent<React.PropsWithChildren<Props>> = ({
  props,
  onApply,
  onCancel,
}) => {
  const messages = useDashMessages({
    cancel: 'retailerMultiPicker.cancel',
    apply: 'retailerMultiPicker.apply',
  })

  return (
    <components.Menu {...props}>
      <Fragment>
        {props.children}
        <MenuActionsApplyContainer>
          <MenuActionButton variant="secondary" onClick={onCancel}>
            {messages.cancel}
          </MenuActionButton>
          <MenuActionButton data-testid={'apply-button'} onClick={onApply}>
            {messages.apply}
          </MenuActionButton>
        </MenuActionsApplyContainer>
      </Fragment>
    </components.Menu>
  )
}
