import React, { useState, useEffect, useMemo, useCallback } from 'react'
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  DecoratedInput,
  NewSelect,
  GridList,
  spacing,
} from '@retailer-platform/shared-components'
import { MENU_BAR_Z_INDEX } from '@retailer-platform/dashboard/gin-and-tonic'
import styled from '@emotion/styled'
import { SearchIcon } from '@instacart/ids-core'
import { EmptyState } from '@instacart/ids-customers'
import { useDomainMessages } from '../../../../utils/domain/intl'
import { type Author, type CollectionType, type OptionData } from '../types'
import { useSearchTerm } from '../useSearchTerm'
import { CollectionSelectionValue, type CollectionSelectorProps } from '../CollectionSelector'
import { getUniqueCollections } from '../utils'
import { useTypeAuthorFilters } from './useTypeAuthorFilters'
import { useAdvancedSearchColumns } from './useAdvancedSearchColumns'
import {
  type CollectionWithCount,
  useRetailerCollectionsWithCounts,
} from './useRetailerCollectionsWithCounts'
import OptionsDropdown from './OptionsDropdown'

const SearchAndFilterContainer = styled.div({
  display: 'flex',
  alignItems: 'center',
  gap: spacing.X16,
  marginBottom: spacing.X4,
})

const FilterContainer = styled.div({
  display: 'flex',
  gap: spacing.X16,
  flexGrow: 1,
})

const GridListContainer = styled.div({
  height: '50vh',
})

const SearchInputWrapper = styled.div({
  flexBasis: '30%',
  flexGrow: 1,
})

const StyledNewSelect = styled(NewSelect)({
  flexBasis: '35%',
  flexGrow: 1,
  width: '150px',
})

interface AdvancedSearchModalProps
  extends Pick<
    CollectionSelectorProps,
    | 'selectionValueType'
    | 'collectionTypes'
    | 'initialSlug'
    | 'additionalOptions'
    | 'retailerId'
    | 'isClearable'
    | 'isMulti'
    | 'excludeDynamicRetailerCollections'
    | 'additionalOptions'
  > {
  value?: string | number | (string | number)[]
  onClose: () => void
  onChange: (selectedOptions?: OptionData[]) => void
  selectedOptionsCache: OptionData[] | null
  initialSearchTerm?: string
}

const AdvancedSearchModal: React.FC<AdvancedSearchModalProps> = ({
  value,
  onClose,
  onChange,
  selectedOptionsCache,
  collectionTypes,
  initialSlug,
  isClearable,
  isMulti,
  excludeDynamicRetailerCollections,
  retailerId,
  selectionValueType = CollectionSelectionValue.CollectionSlug,
  additionalOptions,
  initialSearchTerm,
}) => {
  const i18n = useDomainMessages({
    title: 'storefrontDomain.collections-selector.advancedSearch.title',
    close: 'storefrontDomain.collections-selector.advancedSearch.close',
    save: 'storefrontDomain.collections-selector.advancedSearch.save',
    searchPlaceholder: 'storefrontDomain.collections-selector.advancedSearch.searchPlaceholder',
    typePlaceholder: 'storefrontDomain.collections-selector.advancedSearch.typePlaceholder',
    authorPlaceholder: 'storefrontDomain.collections-selector.advancedSearch.authorPlaceholder',
    empty: 'storefrontDomain.collections-selector.advancedSearch.empty',
    searchForCollection: 'storefrontDomain.collections-selector.advancedSearch.searchForCollection',
  })

  const [hasSetInitialSelection, setHasSetInitialSelection] = useState(false)
  const [selectedCollections, setSelectedCollections] = useState<CollectionWithCount[]>([])
  const [showProductWithItems, setShowProductWithItems] = useState(() => {
    const storedValue = localStorage.getItem('showProductWithItems')
    return storedValue === 'false' ? false : true
  })

  const {
    searchTerm,
    setSearch,
    setSlugs,
    getDebouncedSearchTerm,
    getDebouncedSlugs,
    shouldPerformSearch,
  } = useSearchTerm(initialSearchTerm)

  const {
    typeOptions,
    authorOptions,
    selectedType,
    selectedAuthor,
    handleTypeChange,
    handleAuthorChange,
    getFilteredCollectionTypes,
  } = useTypeAuthorFilters(collectionTypes as CollectionType[])

  const { collectionsWithCounts, isLoading } = useRetailerCollectionsWithCounts({
    retailerId,
    shouldPerformSearch,
    searchTerm: getDebouncedSearchTerm(),
    slugs: getDebouncedSlugs(),
    collectionTypes: getFilteredCollectionTypes(),
    excludeDynamicRetailerCollections,
  })

  const handleToggleSelection = useCallback(
    (collection: CollectionWithCount) => {
      setSelectedCollections(prev => {
        const isSelected = prev.some(c => c.id === collection.id)
        if (!isSelected) {
          // If not multi, replace the entire selection
          return isMulti ? [...prev, collection as OptionData] : [collection as OptionData]
        } else {
          // Allow deselection if clearable or multi
          if (isClearable || isMulti) {
            return prev.filter(c => c.id !== collection.id)
          }
        }
        return prev
      })
    },
    [setSelectedCollections, isClearable, isMulti]
  )

  const columns = useAdvancedSearchColumns({
    onToggleSelection: handleToggleSelection,
    isLoadingProductCounts: isLoading,
    selectionValueType,
    selectedValues: selectedCollections
      ?.map(c => (selectionValueType === CollectionSelectionValue.CollectionId ? c.id : c.slug))
      .filter(v => v != null),
  })

  useEffect(() => {
    if (initialSlug) {
      setSlugs([initialSlug])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // get cached collections and find matching selected collections to store
  useEffect(() => {
    if (value && selectedOptionsCache) {
      const valueArray = Array.isArray(value) ? value : value ? [value] : []
      const cachedCollections = selectedOptionsCache.filter(option =>
        valueArray.includes(option.value)
      )
      setSelectedCollections(cachedCollections)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // set selected collections based on init value
  useEffect(() => {
    if (value && collectionsWithCounts.length && !hasSetInitialSelection) {
      // for enhancing when currently value is available, nothing selected yet but API is available
      // set local state with selected
      const valueArray = Array.isArray(value) ? value : value ? [value] : []
      // get matching collections from collectionsWithCounts based on value
      const matchingCollections = collectionsWithCounts.filter(collection =>
        valueArray.includes(
          selectionValueType === CollectionSelectionValue.CollectionId
            ? collection.id ?? ''
            : collection.slug ?? ''
        )
      )

      if (matchingCollections.length > 0) {
        // avoid duplicate collections by cached collections and newly fetched collections
        setSelectedCollections(prev => getUniqueCollections([...matchingCollections, ...prev]))
      }

      // Set the flag to true after setting initial selection
      setHasSetInitialSelection(true)
    }
  }, [
    value,
    collectionsWithCounts,
    selectedOptionsCache,
    selectedCollections,
    selectionValueType,
    setSlugs,
    hasSetInitialSelection,
  ])

  const handleShowProductWithItemsChange = useCallback(() => {
    setShowProductWithItems(prev => {
      const newValue = !prev
      localStorage.setItem('showProductWithItems', JSON.stringify(newValue))
      return newValue
    })
  }, [])

  const handleSave = useCallback(() => {
    // prepare data for parent onChange
    const optionData: OptionData[] = selectedCollections.map(collection => ({
      ...collection,
      label: collection.name ?? '',
      value:
        selectionValueType === CollectionSelectionValue.CollectionId
          ? collection.id ?? ''
          : collection.slug ?? '',
    }))

    onChange(optionData)
    onClose()
  }, [selectedCollections, selectionValueType, onChange, onClose])

  // Memoized additional options and backfilled with name and slug
  const backfilledAdditionalOptions: CollectionWithCount[] = useMemo(() => {
    if (!shouldPerformSearch) return []

    // filter on search term
    const filteredAdditionalOptions = additionalOptions?.filter(option =>
      option.label.toLowerCase().includes(searchTerm.toLowerCase())
    )
    const backfilled = filteredAdditionalOptions?.map(({ label, value, ...rest }) => ({
      ...rest,
      name: label,
      slug: value,
      collectionType: 'Collection',
    }))
    return backfilled ?? []
  }, [additionalOptions, shouldPerformSearch, searchTerm])

  const prepareGridListData = useCallback(() => {
    // Combine all collections, ensuring uniqueness
    const allCollections = getUniqueCollections([
      ...selectedCollections,
      ...backfilledAdditionalOptions,
      ...collectionsWithCounts,
    ])

    // If showing all products, return the full list
    if (!showProductWithItems) {
      return allCollections
    }

    // Filter collections based on product count and selection status
    return allCollections.filter(
      collection =>
        // Include if product count is undefined (for additional options)
        collection.productCount === undefined ||
        // Include if product count is greater than 0
        (collection.productCount ?? 0) > 0 ||
        // Always include the selected collection
        selectedCollections.some(c => c.id === collection.id)
    )
  }, [
    selectedCollections,
    backfilledAdditionalOptions,
    collectionsWithCounts,
    showProductWithItems,
  ])

  return (
    <Modal
      isOpen
      onRequestClose={onClose}
      maxWidth="80%"
      maxHeight="80vh"
      styleOverrides={{
        overlay: {
          zIndex: MENU_BAR_Z_INDEX + 1,
        },
      }}
    >
      <ModalHeader>{i18n.title}</ModalHeader>
      <ModalBody>
        <SearchAndFilterContainer>
          <SearchInputWrapper>
            <DecoratedInput
              id="collection-search"
              name="collection-search"
              placeholder={i18n.searchPlaceholder}
              value={searchTerm}
              onChange={e => setSearch(e.target.value)}
              decorationComponent={SearchIcon}
              autoFocus
            />
          </SearchInputWrapper>
          <FilterContainer>
            <StyledNewSelect
              id="type-filter"
              placeholder={i18n.typePlaceholder}
              value={selectedType}
              onChange={(value: CollectionType | null) => handleTypeChange(value)}
              options={typeOptions}
              isDisabled={isLoading}
              isClearable
            />
            <StyledNewSelect
              id="author-filter"
              placeholder={i18n.authorPlaceholder}
              value={selectedAuthor}
              onChange={(value: Author | null) => handleAuthorChange(value)}
              options={authorOptions}
              isDisabled={isLoading}
              isClearable
            />
          </FilterContainer>
          <OptionsDropdown
            showProductWithItems={showProductWithItems}
            onShowProductWithItemsChange={handleShowProductWithItemsChange}
          />
        </SearchAndFilterContainer>
        <GridListContainer>
          <GridList
            onLoadMore={() => {
              /* noop */
            }}
            height={500}
            columns={columns}
            data={prepareGridListData()}
            loadingMore={isLoading}
            canLoadMore={false}
            isLoading={isLoading}
            emptyState={
              <EmptyState>{searchTerm ? i18n.empty : i18n.searchForCollection}</EmptyState>
            }
          />
        </GridListContainer>
      </ModalBody>
      <ModalFooter>
        <div css={{ display: 'flex', gap: '8px' }}>
          <Button onClick={onClose} variant="secondary">
            {i18n.close}
          </Button>
          <Button onClick={handleSave} disabled={isLoading} variant="primary">
            {i18n.save}
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  )
}

export default AdvancedSearchModal
