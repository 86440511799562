import React from 'react'
import { createContextHoc } from '../../../legacy/contexts/utils'
import { type ApiDataState } from '../../../legacy/common/types'
import { type WarehouseLocationFeature } from '../../../legacy/common/warehouseLocations'
import { type FeatureEntitlement, type FeatureToggle } from '../../../__codegen__/api'
import { type WarehouseWithLocations } from './queries/warehouse'

export type WarehouseContextValue = ApiDataState<{
  warehouse: WarehouseWithLocations
  warehouses?: WarehouseWithLocations[]
  // a valid ID is a non-zero ID representing an actual Warehouse / Retailer.
  // An ID with a value of zero is a placeholder value until an actual ID is chosen by user
  validId: boolean
  locationFeatures: WarehouseLocationFeature[]
  partnerName: string
  partnerId: number
  partnerType: string
  featureToggles: FeatureToggle[]
  featureEntitlements: FeatureEntitlement[]
}>

export const WarehouseContext = React.createContext<WarehouseContextValue | null>(null)

WarehouseContext.displayName = 'WarehouseContext'

export const withWarehouseContext = createContextHoc(WarehouseContext, {
  displayName: 'withWarehouseContext',
  required: true,
})
