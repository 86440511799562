import React, { useState, useEffect, type FunctionComponent, useMemo } from 'react'
import { Loader } from '@retailer-platform/shared-components/src/common/loader/Loader'
import { ErrorPage } from '../../../gin-and-tonic/containers/error-page/ErrorPage'
import { usePartnerId } from '../../routing/params.hooks'
import { useMeasureTiming } from '../../events/hooks'
import { useFetchPartner } from '../partner/queries/partner'
import { useFetchWarehouse } from './queries/warehouse'
import { WarehouseContext, type WarehouseContextValue } from './WarehouseContext'
import { StaleDataNotification } from './StaleDataNotification'

interface Props {
  ids: string
}

const initialState: WarehouseContextValue = {
  loaded: false,
  loading: false,
  error: false,
  partnerId: undefined,
  partnerName: undefined,
  partnerType: undefined,
  warehouse: undefined,
  warehouses: undefined,
  validId: undefined,
  locationFeatures: undefined,
  featureToggles: undefined,
  featureEntitlements: undefined,
}

const WarehouseProvider: FunctionComponent<React.PropsWithChildren<Props>> = ({
  children,
  ids,
}) => {
  const triggerMeasureEnd = useMeasureTiming(
    {
      id: 'warehouse_provider.loaded',
      description:
        'Time taken to load application initialization data (retailer locations, retailer locales, etc) from WarehouseProvider mount to full load. Note that there may be additional variance due to retailer loading time (not cached due to roulette flags living there)',
    },
    []
  )

  const warehouseIds = useMemo(() => ids.split('-'), [ids])
  const id = warehouseIds[0]

  const {
    warehouse,
    partnerName,
    partnerType,
    locationFeatures,
    featureToggles,
    featureEntitlements,
    loading,
    error,
    errorWhileRevalidating,
    refetch,
  } = useFetchWarehouse({
    id,
  })

  const partnerId = Number(usePartnerId())
  const { warehouses } = useFetchPartner(partnerId)

  const filteredWarehouses = useMemo(
    () => warehouses?.filter(warehouse => warehouseIds.includes(String(warehouse.id))),
    [warehouses, warehouseIds]
  )

  const [contextValue, setContextValue] = useState<WarehouseContextValue>({ ...initialState })

  useEffect(() => {
    if (warehouse && filteredWarehouses) {
      setContextValue({
        loaded: true,
        partnerName,
        partnerId,
        partnerType,
        loading: false,
        error: false,
        warehouse,
        warehouses: filteredWarehouses,
        validId: true,
        locationFeatures,
        featureToggles,
        featureEntitlements,
      })
    }
  }, [
    warehouse,
    filteredWarehouses,
    locationFeatures,
    featureToggles,
    partnerName,
    partnerId,
    featureEntitlements,
    partnerType,
  ])

  useEffect(() => {
    if (id == '0' || id == 'undefined') {
      setContextValue({
        loaded: false,
        partnerName: undefined,
        partnerType: undefined,
        partnerId,
        loading: true,
        error: false,
        warehouse: undefined,
        warehouses: [],
        validId: false,
        locationFeatures: undefined,
        featureToggles: [],
        featureEntitlements: undefined,
      })
    }
  }, [warehouse, featureToggles, partnerName, partnerId, featureEntitlements, id])

  useEffect(() => {
    if (loading) {
      setContextValue({
        loaded: false,
        loading: true,
        partnerId: undefined,
        partnerName: undefined,
        partnerType: undefined,
        error: false,
        warehouse: undefined,
        warehouses: undefined,
        validId: undefined,
        locationFeatures: undefined,
        featureToggles: undefined,
        featureEntitlements: undefined,
      })
    }
  }, [loading])

  useEffect(() => {
    if (error) {
      setContextValue({
        loaded: false,
        loading: false,
        partnerId: undefined,
        partnerName: undefined,
        partnerType: undefined,
        error: true,
        warehouse: undefined,
        warehouses: undefined,
        validId: undefined,
        locationFeatures: undefined,
        featureToggles: undefined,
        featureEntitlements: undefined,
      })
    }
  }, [error])

  useEffect(() => {
    if (!loading && !error && warehouse)
      triggerMeasureEnd({
        retailerId: id,
        cacheEnabled:
          !!featureToggles
            .map?.(v => v.name)
            .includes?.('rt_retailer_stale_while_revalidate_enabled') ?? false,
      })
  }, [error, featureToggles, id, loading, triggerMeasureEnd, warehouse])

  if (error) return <ErrorPage action={() => refetch()} />

  // when Id is not valid ( === '0') then it means we haven't selected a retailer yet
  // In that case we want to showChildren, since we need to display the retailer selector
  const showChildren = contextValue.loaded || contextValue.validId === false

  return (
    <WarehouseContext.Provider value={contextValue}>
      {errorWhileRevalidating && <StaleDataNotification refetch={refetch} />}

      {showChildren ? children : <Loader />}
    </WarehouseContext.Provider>
  )
}

export default WarehouseProvider
