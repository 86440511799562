import React, { lazy } from 'react'
import { DashSwitch } from '@retailer-platform/dashboard/routing'
import { DomainRoute } from '../utils/domain/routingComponents'
import { retailerCollectionsV2AccessControl } from '../access-control/retailerCollectionsV2Access.configuration'
import { retailerCollectionsV3AccessControl } from '../access-control/retailerCollectionsV3Access.configuration'

const RetailerCollectionsV2ListPage = lazy(
  () =>
    import(
      /* webpackChunkName: "RetailerCollectionsV2ListPage" */
      '../pages/RetailerCollectionsV2ListPage'
    )
)
const RetailerCollectionsV2CreatePage = lazy(
  () =>
    import(
      /* webpackChunkName: "RetailerCollectionsV2CreatePage" */
      '../pages/RetailerCollectionsV2CreatePage'
    )
)
const RetailerCollectionsV2DetailsPage = lazy(
  () =>
    import(
      /* webpackChunkName: "RetailerCollectionsV2DetailsPage" */
      '../pages/RetailerCollectionsV2ViewPage'
    )
)
const RetailerCollectionsV2EditPage = lazy(
  () =>
    import(
      /* webpackChunkName: "RetailerCollectionsV2EditPage" */
      '../pages/RetailerCollectionsV2EditPage'
    )
)
const RetailerCollectionsV2BulkUploadPage = lazy(
  () =>
    import(
      /* webpackChunkName: "RetailerCollectionsBulkUploadStructurePage" */
      '../pages/RetailerCollectionsV2BulkUploadPage'
    )
)
const RetailerCollectionsV3ListPage = lazy(
  () =>
    import(
      /* webpackChunkName: "RetailerCollectionsV3ListPage" */
      '../pages/RetailerCollectionsV3ListPage'
    )
)
const RetailerCollectionsFlyersPage = lazy(
  () => import('../pages/flyers/RetailerCollectionsFlyersPage')
)
const RetailerCollectionsFlyersBuildPage = lazy(
  () => import('../pages/flyers-build/RetailerCollectionsFlyersBuildPage')
)
const RetailerCollectionsFlyersReviewPage = lazy(
  () => import('../pages/flyers-review/RetailerCollectionsFlyersReviewPage')
)
// @hygen:inject page-import

export const v1Router = (
  <DomainRoute route="retailer-collections" accessControl={retailerCollectionsV2AccessControl}>
    <DashSwitch>
      <DomainRoute
        exact
        route="retailer-collections"
        component={RetailerCollectionsV2ListPage}
        scopePicker={{
          titles: ['retailerCollectionsDomain.v2.list.title'],
        }}
      />
      <DomainRoute
        exact
        route="retailer-collections-list"
        component={RetailerCollectionsV2ListPage}
      />
      <DomainRoute
        exact
        route="retailer-collections-browse"
        component={RetailerCollectionsV2ListPage}
      />
      <DomainRoute
        exact
        route="retailer-collections-create"
        component={RetailerCollectionsV2CreatePage}
      />
      <DomainRoute
        exact
        route="retailer-collections-view"
        component={RetailerCollectionsV2DetailsPage}
      />
      <DomainRoute
        exact
        route="retailer-collections-edit"
        component={RetailerCollectionsV2EditPage}
      />

      <DomainRoute
        exact
        route="retailer-collections-bulk-upload"
        component={RetailerCollectionsV2BulkUploadPage}
      />

      <DomainRoute
        exact
        route="retailer-collections-flyers"
        component={RetailerCollectionsFlyersPage}
        scopePicker={{
          titles: ['retailerCollectionsDomain.flyers.list.title'],
        }}
      />
      <DomainRoute
        exact
        route="retailer-collections-flyers-build"
        component={RetailerCollectionsFlyersBuildPage}
      />
      <DomainRoute
        exact
        route="retailer-collections-flyers-review"
        component={RetailerCollectionsFlyersReviewPage}
      />
      {/* @hygen:inject router-route injection point for routes, please do not delete */}
    </DashSwitch>
  </DomainRoute>
)
export const v2Router = (
  <DomainRoute route="retailer-collections-v2" accessControl={retailerCollectionsV2AccessControl}>
    <DashSwitch>
      <DomainRoute
        exact
        route="retailer-collections-v2"
        component={RetailerCollectionsV2ListPage}
      />
      <DomainRoute
        exact
        route="retailer-collections-v2-list"
        component={RetailerCollectionsV2ListPage}
      />
      <DomainRoute
        exact
        route="retailer-collections-v2-create"
        component={RetailerCollectionsV2CreatePage}
      />
      <DomainRoute
        exact
        route="retailer-collections-v2-view"
        component={RetailerCollectionsV2DetailsPage}
      />
      <DomainRoute
        exact
        route="retailer-collections-v2-edit"
        component={RetailerCollectionsV2EditPage}
      />
      <DomainRoute
        exact
        route="retailer-collections-v2-bulk-upload"
        component={RetailerCollectionsV2BulkUploadPage}
      />

      {/* @hygen:inject router-route injection point for routes, please do not delete */}
    </DashSwitch>
  </DomainRoute>
)
export const v3Router = (
  <DomainRoute route="retailer-collections-v3" accessControl={retailerCollectionsV3AccessControl}>
    <DashSwitch>
      <DomainRoute
        exact
        route="retailer-collections-v3"
        component={RetailerCollectionsV3ListPage}
      />
      <DomainRoute
        exact
        route="retailer-collections-v3-list"
        component={RetailerCollectionsV3ListPage}
      />

      {/* @hygen:inject router-route injection point for routes, please do not delete */}
    </DashSwitch>
  </DomainRoute>
)
