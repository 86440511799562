import React, { type FunctionComponent, useCallback, useMemo } from 'react'
import { useHistory } from 'react-router'
import { type InstacartStoreConfiguration } from '../../../__codegen__/api'
import { useDashMessage } from '../../../intl/intl.hooks'
import useAccessControl from '../../../legacy/components/AccessControl/useAccessControl'
import MultiScopePicker from './MultiScopePicker'

interface Props {
  storeConfigIds: string[]
  availableStoreConfigs: InstacartStoreConfiguration[]
  retailerInsufficientPermission?: boolean
}

const MultiStoreConfigScopePicker: FunctionComponent<React.PropsWithChildren<Props>> = ({
  storeConfigIds,
  availableStoreConfigs,
  retailerInsufficientPermission,
}) => {
  const history = useHistory()
  const sitesMessage = useDashMessage('retailerMultiPicker.sites')

  const dropdownStoreConfigOptions = useMemo(
    () =>
      availableStoreConfigs.map(e => ({
        context: e,
        label: e.brandedName,
        value: String(e.id),
      })),
    [availableStoreConfigs]
  )

  const newStoreConfigsRedirectFn = useCallback(
    (ids: number[]) => {
      const newPath = location.pathname.replace(
        /\/sites\/(undefined|[\d-]+)/,
        '/sites/' + (ids.join('-') || '0')
      )

      history.push(newPath)
    },
    [history]
  )

  return (
    <MultiScopePicker
      options={dropdownStoreConfigOptions}
      selectedOptions={storeConfigIds.map(e => ({ value: e }))}
      urlRedirectFn={newStoreConfigsRedirectFn}
      defaultText={sitesMessage}
    />
  )
}

export default MultiStoreConfigScopePicker
