import { chain } from 'lodash'
import { type OptionData } from './types'
import { type CollectionWithCount } from './advancedSearch/useRetailerCollectionsWithCounts'

export function getUniqueCollections<T extends CollectionWithCount | OptionData>(
  collections: T[]
): T[] {
  return chain(collections)
    .compact() // Remove null and undefined values
    .reverse()
    .uniqBy(collection => ('id' in collection ? collection.id : collection.slug))
    .reverse()
    .value()
}
