import React from 'react'
import { Checkbox, type GridColumns } from '@retailer-platform/shared-components'
import styled from '@emotion/styled'
import moment from 'moment'
import { LoadingDots } from '@retailer-platform/shared-components'
import { Link } from '@instacart/ids-customers'
import { OpenInIcon } from '@instacart/ids-core'
import { type Collection, CollectionType } from '../types'
import { useDomainMessages } from '../../../../utils/domain/intl'
import { CollectionSelectionValue } from '../CollectionSelector'
import { useCollectionPreview } from '../useCollectionPreview'
import { type CollectionWithCount } from './useRetailerCollectionsWithCounts'

interface UseAdvancedSearchColumnsProps {
  onToggleSelection: (collection: CollectionWithCount) => void
  isLoadingProductCounts: boolean
  selectionValueType: CollectionSelectionValue
  selectedValues: (string | number)[]
}

const StyledCollectionType = styled.span(() => ({
  textTransform: 'capitalize',
}))

const NameCell = styled.div(() => ({
  display: 'flex',
  alignItems: 'center',
  maxWidth: '100%',
}))

const CollectionName = styled.span(() => ({
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  flex: 1,
  minWidth: 0,
}))

const StyledLink = styled(Link)(() => ({
  display: 'block',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}))

const StyledOpenInIcon = styled(OpenInIcon)(() => ({
  flexShrink: 0,
  marginLeft: '4px',
}))

const isInvalidSlug = (slug: string | undefined): boolean => /^(rc-|n-)$/.test(slug || '')

export const useAdvancedSearchColumns = ({
  onToggleSelection,
  isLoadingProductCounts,
  selectionValueType,
  selectedValues,
}: UseAdvancedSearchColumnsProps): GridColumns<CollectionWithCount> => {
  const i18n = useDomainMessages({
    nameHeader: 'storefrontDomain.collections-selector.advancedSearch.columns.name',
    slugHeader: 'storefrontDomain.collections-selector.advancedSearch.columns.slug',
    typeHeader: 'storefrontDomain.collections-selector.advancedSearch.columns.type',
    authorHeader: 'storefrontDomain.collections-selector.advancedSearch.columns.author',
    productsHeader: 'storefrontDomain.collections-selector.advancedSearch.columns.products',
    lastEditedHeader: 'storefrontDomain.collections-selector.advancedSearch.columns.lastEdited',
    department: 'storefrontDomain.collections-selector.advancedSearch.type.department',
    collection: 'storefrontDomain.collections-selector.advancedSearch.type.collection',
    hub: 'storefrontDomain.collections-selector.advancedSearch.type.hub',
    holiday: 'storefrontDomain.collections-selector.advancedSearch.type.holiday',
    instacart: 'storefrontDomain.collections-selector.advancedSearch.author.instacart',
    retailer: 'storefrontDomain.collections-selector.advancedSearch.author.retailer',
    dynamic: 'storefrontDomain.collections-selector.advancedSearch.columns.dynamic',
  })

  const { getCollectionPreviewUrl } = useCollectionPreview()

  // Helper functions
  const getCollectionTypeDisplay = (data: CollectionWithCount): string => {
    const { slug, collectionType } = data

    if (slug?.startsWith('n-') || collectionType === CollectionType.DEPARTMENT) {
      return i18n.department
    } else if (slug?.startsWith('rc-')) {
      return i18n.collection
      // translation purpose only
    } else if (collectionType === CollectionType.HUB) {
      return i18n.hub
    } else if (collectionType === CollectionType.HOLIDAY) {
      return i18n.holiday
    }

    // fallback
    return collectionType || ''
  }

  const getCollectionAuthor = (data: CollectionWithCount): string => {
    const { slug } = data

    if (slug?.startsWith('n-') || slug?.startsWith('rc-')) {
      return i18n.retailer
    }
    // everything else is instacart
    return i18n.instacart
  }

  // Format the collection name to display only the first and last part of the name
  const formatCollectionName = (name: string) => {
    const parts = name.split(' / ')
    if (parts.length > 2) {
      return `${parts[0]} / ... / ${parts[parts.length - 1]}`
    }
    return name
  }

  // Get product count or display loading state
  const getProductCount = (collection: CollectionWithCount): React.ReactNode => {
    if (isLoadingProductCounts) {
      return <LoadingDots height={16} />
    }

    const { slug, collectionType, productCount } = collection
    const isDynamic =
      slug?.startsWith('n-') ||
      collectionType === CollectionType.DEPARTMENT ||
      getCollectionAuthor(collection) === i18n.instacart

    return isDynamic ? i18n.dynamic : productCount ?? 'N/A'
  }

  // Define and return the columns configuration
  return [
    {
      id: 'selection',
      Cell: ({ row }) => {
        const { id, slug } = row.original
        const isSlugSelection = selectionValueType === CollectionSelectionValue.CollectionSlug
        const isDisabled = isSlugSelection && isInvalidSlug(slug)
        const value = isSlugSelection ? slug : id
        const checked = selectedValues.includes(value ?? '')

        return (
          <Checkbox
            checked={checked}
            onChange={() => onToggleSelection(row.original)}
            disabled={isDisabled}
          />
        )
      },
      width: 45,
    },
    {
      id: 'name',
      Header: i18n.nameHeader,
      Cell: ({ row }) => (
        <NameCell>
          <CollectionName title={row.original.name}>
            {isInvalidSlug(row.original.slug) ? (
              formatCollectionName(row.original.name ?? '')
            ) : (
              <StyledLink
                href={getCollectionPreviewUrl(row.original.slug ?? '')}
                colorStyle={'systemGrayscale70'}
                typographyStyle={'linkMedium'}
                target="_blank"
              >
                {formatCollectionName(row.original.name ?? '')}
              </StyledLink>
            )}
          </CollectionName>
          {/* to prevent the icon from being hidden by long names */}
          {!isInvalidSlug(row.original.slug) && <StyledOpenInIcon size={12} />}
        </NameCell>
      ),
      width: 250,
    },
    { id: 'slug', Header: i18n.slugHeader, Cell: ({ row }) => row.original.slug, width: 200 },
    {
      id: 'collectionType',
      Header: i18n.typeHeader,
      Cell: ({ row }) => (
        <StyledCollectionType>{getCollectionTypeDisplay(row.original)}</StyledCollectionType>
      ),
      width: 150,
    },
    {
      id: 'author',
      Header: i18n.authorHeader,
      Cell: ({ row }) => getCollectionAuthor(row.original),
      width: 100,
    },
    {
      id: 'productCount',
      Header: i18n.productsHeader,
      Cell: ({ row }) => getProductCount(row.original),
      width: 100,
    },
    {
      id: 'lastEdited',
      Header: i18n.lastEditedHeader,
      Cell: ({ row }: { row: { original: Collection } }) =>
        moment(row.original.updatedAt).format('MM/DD/YYYY'),
      sortType: (rowA, rowB) => {
        const dateA = moment(rowA.original.updatedAt)
        const dateB = moment(rowB.original.updatedAt)
        return dateA.valueOf() - dateB.valueOf()
      },
      width: 150,
      canSort: true,
      sortDescFirst: true,
    },
  ]
}
