import React, { type FunctionComponent, useCallback, useMemo } from 'react'
import { useHistory } from 'react-router'
import { type WarehouseWithFeatures } from '../../../utils/contexts/warehouse/queries/warehouse'
import { useDashMessage } from '../../../intl/intl.hooks'
import MultiScopePicker from './MultiScopePicker'

interface Props {
  retailerIds: string[]
  availableWarehouses: WarehouseWithFeatures[]
}

const MultiBannerScopePicker: FunctionComponent<React.PropsWithChildren<Props>> = ({
  retailerIds,
  availableWarehouses,
}) => {
  const history = useHistory()
  const bannerMessage = useDashMessage('retailerMultiPicker.banners')

  const dropdownWarehouseOptions = useMemo(() => {
    if (!availableWarehouses) {
      return []
    }

    return availableWarehouses?.map(e => ({
      context: e,
      label: e.name,
      value: String(e.id),
      storeConfigurations: e.storeConfigurations,
      logo: e.logo,
    }))
  }, [availableWarehouses])

  const newWarehousesRedirectFn = useCallback(
    (ids: number[], clearSites: boolean = false) => {
      let newPath = location.pathname.replace(
        /\/warehouses\/(undefined|[\d-]+)/,
        '/warehouses/' + (ids.join('-') || '0')
      )

      if (clearSites) {
        newPath = newPath.replace(/\/sites\/(undefined|[\d-]+)/, '/sites/0')
      }

      history.push(newPath)
    },
    [history]
  )

  return (
    <MultiScopePicker
      options={dropdownWarehouseOptions}
      selectedOptions={retailerIds.map(e => ({ value: e }))}
      urlRedirectFn={newWarehousesRedirectFn}
      defaultText={bannerMessage}
    />
  )
}

export default MultiBannerScopePicker
