import React, { type FunctionComponent, useContext } from 'react'
import { type InstacartStoreConfiguration } from '../../../__codegen__/api'

export interface SelectedStoreConfigurationContext {
  selectedStoreConfiguration: InstacartStoreConfiguration
  selectedStoreConfigurations: InstacartStoreConfiguration[]
}

const DEFAULT_VAL: SelectedStoreConfigurationContext = {
  selectedStoreConfiguration: null,
  selectedStoreConfigurations: null,
}

const StoreConfigContext = React.createContext(DEFAULT_VAL)

export const SelectedStoreConfigurationProvider: FunctionComponent<
  React.PropsWithChildren<React.PropsWithChildren<SelectedStoreConfigurationContext>>
> = props => (
  <StoreConfigContext.Provider value={{ ...props }}>{props.children}</StoreConfigContext.Provider>
)

export const useSelectedStoreConfiguration = () => useContext(StoreConfigContext)
